import React from 'react'
import { HashRouter as Router, Route } from 'react-router-dom'

import AnimatedRouter from 'react-animated-router'; //我们的AnimatedRouter组件
import 'react-animated-router/animate.css'; //引入默认的动画样式定义

import Layout from '../layout/layout'

// import appConfig from '../config/app-config'

import AppRoutes from './router'

const allRoutes = [
  ...AppRoutes,
]

/**
 * 获取所有路由
 * @returns {*[]}
 */
function getAllRouter(config) {
  return config
    .map(route => {
      if (route.children) {
        return getAllRouter(route.children)
      } else {
        return (
          <Route
            key={route.key}
            path={route.path}
            component={route.component}
            exact={!!route.exact}
          />
        )
      }
    })
}

function Routes() {
  return (
    // basename={appConfig.basePath}
    <Router>
      <Layout>
        <AnimatedRouter>
          {getAllRouter(allRoutes)}
          {/* <Redirect path={'*'} to={'/GradRedEnvelopes'} /> */}
        </AnimatedRouter>
      </Layout>
    </Router>
  )
}

export default Routes