export const en={
 'title': "Red Packet",
 'sendCode':"Send",
 'enterPhone':"Enter your email",
 'phoneEr':'Error: invalid email',
 'enterCode':'Enter email verification code',
 'codeEr':'Error: invalid verification code',
 'receiveing':'Opening…',
 'NetworkError': 'Error: network connection issue',
 'invalidCode':'Invalid verification code',
 'codeError':'Invalid verification code',
 'expire':'The red packet has expired.',
 'incorrectRed':'Invalid link',
 'paramError':'Error: invalid parameters',
 'serviceError':'Error: service declined',
 'Error':'network connection issue',
 'wait':'Frequent requests. Please try again after 1 min.',
 'pending':'Verification failed, please try again',
 'enterYouPhone':'Enter your email',
 'code':'Enter the verification code',
 'receive':'Open',
 'ruleText':'Red packet is valid for 24 hours.',
 'ruleText1':'A Poolin account is needed to store assets received from red packet. Please sign up one within 72 hours if you are not a client of Poolin.',
 'ruleText2':'Opening red packet requires your email',
 'ruleText3':'All rights reserved.',
 'ruleTitle':'Rules',
 'tip':'Apple.inc is not a sponsor or involved in the Red Packet in any manner.',
 'tipText':'Notice',
 'invalidLink':'Invalid link',
 'loading':'Loading',
 'watingNet':'Error: network connection issue, please try again',
 'text1':'Assets from red packet will be refunded to the sender if you are failed to sign up a Poolin account within 72 hours.',
 'regist':'Sign up now',
 'isNill':'Too late. Red packet has been claimed by others.',
 'come':'Sent by {{msg}}',
 'getCount':'{{msg}}/{{msg1}} have been claimed. Total amount: {{msg2}}/{{msg3}} {{msg4}}',
 'second':'s',
 'minute':'min',
 'hour':'h',
 'tip2':'{{msg}} red packet(s) have been claimed in {{msg1}} with a total amount of {{msg2}} {{msg3}}~',
 'tip3':'Red pack {{msg}}/{{msg1}} with a total amount of {{msg2}} {{msg3}}'
}