import AsyncComponent from '../AsyncComponent'

const Routes = [
  {
    // 抢红包首页
    key: '/GradRedEnvelopes',
    path: '/GradRedEnvelopes',
    component: AsyncComponent(() => import('../pages/GradRedEnvelopes/main'))
  },
  {
    // 抢红包首页
    key: '/RedEnvelopesList',
    path: '/RedEnvelopesList',
    component: AsyncComponent(() => import('../pages/RedEnvelopesList/main'))
  },
]

export default Routes