export const zh={
  "title": "领红包",
  "sendCode": "获取验证码",
  'enterYouPhone':'输入您的邮箱账号',
  'ruleText2':'红包仅支持邮箱账号领取；',
  "enterPhone": "请输入邮箱账号",
  'phoneEr':'输入邮箱账号格式有误，请重新输入',
  'enterCode':'请输入邮箱验证码',
  'codeEr':'输入验证码格式有误，请重新输入',
  'receiveing':'领取中...',
  'NetworkError':'请检查网络',
  'invalidCode':'无效的验证码',
  'codeError':'验证码有误',
  'expire':'红包已过期',
  'incorrectRed':'红包口令有误',
  'paramError':'参数不合法',
  'serviceError':'服务出现异常',
  'Error':'网络错误',
  'wait':'请等待60s,发送太频繁,休息一下吧',
  'pending':'验证未通过，请稍后重试',
  'code':'输入验证码',
  'receive':'领',
  'ruleText':'红包领取有效期为24小时；',
  'ruleText1':'已领取红包的用户需要在72小时内注册币印账号，否则系统自动收回；',

  'ruleText3':'红包最终解释权归币印钱包所有，若发现用户违规作弊领取，有权收回红包；',
  'ruleTitle':'红包规则',
  'tip':'本活动与apple.inc无关',
  'tipText':'提示',
  'invalidLink':'无效的红包链接',
  'loading':'加载中...',
  'watingNet':'网络异常,请稍后再试',
  'text1':'请在72小时内注册币印钱包，否则您的红包将被自动退回',
  'regist':'立即注册',
  'isNill':'太火了，红包一抢而空',
  'come':'来自 {{msg}} 的红包',
  'getCount':'红包已领取{{msg}}/{{msg1}}个，共{{msg2}}/{{msg3}} {{msg4}}',
  'second':'秒',
  'minute':'分钟',
  'hour':'小时',
  'tip2':'{{msg}}个红包在{{msg1}}内抢光，共{{msg2}} {{msg3}}',
  'tip3':'币印红包{{msg}}/{{msg1}},共{{msg2}} {{msg3}}'
}