import React from 'react'
import { withRouter } from 'react-router-dom'
import styles from './index.module.less'
import Cookie from "js-cookie";
import i18n from 'i18next';
const excludeLocationRegExp = /\/main/


/**
 * 布局层
 */
// @withRouter
class MyLayout extends React.PureComponent {
  render() {
    const { location } = this.props
    let lang = navigator.language||navigator.userLanguage;
    lang = lang.substr(0, 2);//截取lang前2位字符
    if(lang =='zh'){
      i18n.changeLanguage('CN')
      Cookie.set('Redlang','CN')
    }else{
      i18n.changeLanguage('EN')
      Cookie.set('Redlang','EN')
    }

    if (location.pathname.match(excludeLocationRegExp)) {
      return <div className={styles['no-layout']}>{this.props.children}</div>
    }

    return (
      <div className={styles['layout-box']}>
        {this.props.children}
      </div>
    )
  }
}

export default withRouter(MyLayout)