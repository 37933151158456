import { action } from 'mobx'

class GlobalStore {
    userInfo = {
        name: 'yqh',
        age: '27'
    };
    redEnvelopesObj = {
        // 列表页数据

    };

    @action.bound setName (state) {
        this.userInfo.name = state
    }

    @action.bound setRedEnvelopesObj (state) {
        this.redEnvelopesObj = state
    }

    @action.bound getDataFromSessionStorage = () => {
        this.redEnvelopesObj = sessionStorage.getItem("redEnvelopesObj") 
        ? JSON.parse(sessionStorage.getItem("redEnvelopesObj")) : "";
    };

    @action.bound setDataFromSessionStorage = () => {
        sessionStorage.setItem("redEnvelopesObj",JSON.stringify(this.redEnvelopesObj));
    };
}


export default new GlobalStore()