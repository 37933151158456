import React from 'react';

import './App.css';

import Routes from './routes/Routes';

import { Provider } from 'mobx-react';
import { configure } from 'mobx';
import storeList from './store';

/**
 * Mobx开启严格模式
 */
configure({
  enforceActions: 'observed'
});

class App extends React.Component {

  render() {
    return (
      <Provider {...storeList}>
          <Routes />
      </Provider>
    );
  }
  
}

export default App;
