import * as React from 'react';
import Loadable from 'react-loadable';

/**
 * @default react 异步加载组件
 * @param component promise函数 最终会返回一个react 组件
 */
export default (component) =>
  Loadable({
    loader: component,
    loading(props) {
      if (props.error) {
        return (
          <div style={{ padding: 40 }}>
            <h1>you have ERROR!</h1>
            {props.error && <h2 style={{ color: 'red' }}>{`${props.error}`}</h2>}
          </div>
        )
      }
      return null
    },
  })